import {useTranslation} from 'react-i18next'
import {useNavigate} from 'react-router-dom'
import {APP_CONSTANT} from '../helperComponents/Constants'
import React from 'react'
import {analytics} from '../helperComponents/Analytics/Analytics'
import {AMPLITUDE_SCREEN_NAME} from '../helperComponents/Analytics/AnalyticsConstants'

export default function MobileAppCookiesButton() {
  const {t} = useTranslation()
  const navigate = useNavigate()

  function handleMobileAppCookiesClick() {
    analytics.fireMobileAppCookiesTappedEvent(AMPLITUDE_SCREEN_NAME.PRIVACY_LANDING_PAGE)
    navigate('/mobile-app-cookies')
  }

  return (
    <div className="mobile-app-cookies-button" data-testid="mobile-app-cookies-button">
      {(APP_CONSTANT.CONFIG.REGION === 'EU' || APP_CONSTANT.CONFIG.COUNTRY_CODE === 'ARE') && (
        <div className="mobile-app-cookies-button__content__button-wrapper">
          <button className="fmc-button fmc-button-addon" onClick={handleMobileAppCookiesClick}>
            <span>{t('lbl_Mobile App Cookies')}</span>
          </button>
        </div>
      )}
    </div>
  )
}
